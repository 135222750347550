<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : 'white'"
      :dark="$vuetify.theme.dark"
      class="px-sm text-left shadow-sm"
      height="75"
      app
      clipped-left
    >
      <v-container fluid class="d-flex align-center">
        <v-app-bar-nav-icon
          v-if="false"
          class="d-flex d-md-none"
          v-ripple="{ class: 'primary--text' }"
          @click="toggleVerticalSaasSidebarDrawer"
        />
        <v-progress-linear
          :active="getThemeMode.isLoading"
          :indeterminate="getThemeMode.isLoading"
          absolute
          bottom
          color="primary"
        />

        <v-toolbar-title class="mb-0 ">
          <v-img class="d-inline-block" src="@/assets/images/SmallLogoMassageApp.png" style="vertical-align: middle;" width="70" />
          <span class="d-none d-md-inline-block ml-2"  style="line-height: 95%; vertical-align: middle;">Massage<br/>App</span>
          <v-btn 
              v-if="!this.$router.currentRoute.path.toLowerCase().includes('homepage')" class="text-capitalize ml-8" outlined
              @click="$router.push('/')"
          >
              <v-icon left>
                mdi-chevron-left
              </v-icon>              
              {{ $t("home.back to home") }}
          </v-btn>
          
        </v-toolbar-title>
 
        <v-spacer />

        <!--<v-badge
          bordered
          overlap          
          content="1"
          color="red"
          offset-x="22"
          offset-y="22"
        >
          <v-btn icon @click="notificationDrawer = !notificationDrawer">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </v-badge>-->

        <v-btn icon @click="notificationDrawer = !notificationDrawer">
          <v-icon>mdi-bell</v-icon>
        </v-btn>

        <!-- <v-btn icon @click="searchDrawer = !searchDrawer">
          <v-icon>mdi-magnify</v-icon>
        </v-btn> -->

        <v-chip id="userdrawerclicktarget" pill class="transparent py-5" @click="userDrawer = !userDrawer">
          <!-- Hi, Watson -->
          <v-avatar class="ml-2" id="headeravatar">

            <v-img v-if="profilePic.endsWith('undefined') || profilePic.endsWith('null')" :src="'https://ik.imagekit.io/platt4mresources/massageapp/8714556a52021ba3a55c8e7a3547d28c.jpg?updatedAt=1731701496621'" ></v-img>
            <v-img v-else :src="profilePic" ></v-img>
          </v-avatar>
        </v-chip>
      </v-container>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right=""
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            @click.native="logoutUser"
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <v-navigation-drawer
      v-model="notificationDrawer"
      fixed
      right=""
      height="100%"
      temporary
      floating
      width="350"
    >
      <notification-drawer>
        <template v-slot:notificationDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="notificationDrawer = !notificationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <!--<template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="View All Notifications"
            block
            bg-color="primary lighten-5 primary--text"
          />
        </div>
      </template>-->
    </v-navigation-drawer>
    <!-- searchDrawer -->
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      right=""
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn icon color @click.stop="searchDrawer = !searchDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>    
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserDrawer from "../common-drawer/UserDrawer.vue";
import NotificationDrawer from "../common-drawer/NotificationDrawer.vue";
import SearchDrawer from "../common-drawer/SearchDrawer.vue";
export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer,
    NotificationDrawer,
    SearchDrawer,
  },
  computed: {
    ...mapGetters(["getThemeMode", "errorPopup", "profilePic"]),

  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
    };
  },
  methods: {
    ...mapActions(["changeVerticalSaasSidebarDrawer", "signOut", "showErrorPopup", "hideErrorPopup"]),
    toggleVerticalSaasSidebarDrawer() {
      this.changeVerticalSaasSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    logoutUser() {
      this.signOut();

      this.$router.push("/auth/login");
    }
  },

};
</script>

<style scoped></style>
